form > section > p {
  margin: 10px 0 30px;
}

/*
form > section > p ~ div {
  margin-top: 1em;
}
*/

textarea {
  width: 100%;
  height: 20em;
}

label input {
  margin-bottom: 2em;
}

input {
  font-size: 1.5rem;
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}

input[type="text"],
input[type="email"],
input[type="search"],
input#password {
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  outline: none;
}

input#password {
  width: calc(100% - 3em);
}

main > p + form {
  margin-top: 3em;
}

form section.input {
  position: relative;
}

section.input label {
  color: #aaa;
  transition: 0.3s;
  letter-spacing: 0.3px;
  font-size: 1.25rem;
  margin-bottom: 0.5em;
}


section.input.focused label,
section.input.valid label {
  letter-spacing: 0.5px;
  color: #3399ff;
}

input[type="submit"] {
  padding: 0.2em 0.5em;
  border: 1px solid #ccc;
  float: right;
}

form::after {
  content: '';
  clear: both;
  display: block;
}

form details {
  width: 100%;
  transition: 0.4s;
}

form details {
  color: white;
}

form details[open] {
  color: #333;
}

form details > summary {
  color: #aaa;
  border: none;
  cursor: pointer;
  list-style: none;
}

form details:focus > summary,
form details > summary:focus {
    color: #333;
}

form details > summary::after {
  content: '...';
}

form details > summary::-webkit-details-marker {
  display: none;
}

.error {
  color: red;
}

.input-decoration {
  position: absolute;
  right: 0;
  top: 2em;
  height: 2em;
  color: #aaa;
  fill: currentColor;
}

button.input-decoration {
  width: 2em;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

fieldset {
  margin: 4em 0;
}

fieldset > div {
  margin: 1.5em;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0 0.5em 0 0;
}