@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700|Quicksand:300,600&display=swap&subset=latin-ext');

body {
  font-family: Montserrat, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: blue;
  background: linear-gradient(240deg, #3344FF, #3388FF, #5555FF);
  animation: animatedgradient 60s ease alternate infinite;
  background-image: url(./background.svg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
  position: relative;
}

#root {
  max-width: 40em;
  background-color: white;
  border: 4vw solid transparent;
  border-radius: 9vw 20px;
  padding: 2em;
  margin: 4em;
}

#root > header {
  text-align: center;
}

#root > header img {
  margin: 3em auto 5em;
  max-width: 25em;
}

#root > footer {
  position: absolute;
  margin: 0 2em;
  bottom: 1em;
  left: 0;
  width: calc(100% - 4em);
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: white;
}

#root > footer > div {
  display: inline-block;
  margin: 0 0.6em;
}

#root > footer span {
  padding: 0.15em;
  letter-spacing: 0.4px;
}

main > section {
  margin-bottom: 6em;
}

main > section:last-child {
  margin-bottom: inherit;
}

main > section::before {
  content: '';
  clear: both;
}

article > section, article > blockquote {
  clear: both;
  margin: 4em 0;
  border: 1px solid transparent;
}

blockquote {
  padding: 4em 0;
  quotes: "\201C""\201D";
  font-family: "Times New Roman", Times, serif;
}

blockquote::before {
  font-family: "Times New Roman", Times, serif;
  color: #ddd;
  content: open-quote;
  font-size: 4em;
  width: 3rem;
  float: left;
  line-height: 0.5em;
}

blockquote:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

blockquote p {
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 3rem;
}

blockquote footer {
  text-align: right;
  margin-left: 3rem;
}

aside {
  display: inline-block;
  margin: 3em 0;
  padding: 1em 0;
  border-top: 2px solid blue;
  border-bottom: 2px solid blue;
}

img.section {
  display: block;
  margin: 4em auto 3em;
  padding: 1em;
  width: 75%;
  max-width: 25em;
}

a.inline {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  font-family: Quicksand, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  margin: 2.8em 0 0.2em;
}

h3 {
  margin-bottom: 0;
}

h3 + p {
  margin-top: 0;
}

a.button,
input[type="submit"] {
  margin: 1em 0;
  font-weight: 400;
  border-radius: 0.3em;
  box-shadow: -2px 3px 6px darkgray;
}

a.button {
  display: inline-block;
  min-width: 6em;
  font-size: 1.5rem;
  padding: 0.75em 2em;
  text-decoration: none;
  color: #3344FF;
  border: 2px solid #3344FF;
}

a.button,
input[type="submit"]:enabled {
  font-weight: 600;
  background-color: darkgray;
  border: 1px solid darkgray;
  color: white;
  text-shadow: -1px 1px 1px rgba(255,255,255,0.5);
}

a.button.primary:hover,
a.button.primary:focus,
input[type="submit"]:enabled:hover,
input[type="submit"]:enabled:focus {
  background-image: linear-gradient(182deg, #55CC66 0%, #44BB55 20%, #339944 100%);
}

a.button:active,
a.button:disabled,
input[type="submit"]:active,
input[type="submit"]:disabled {
  margin-top: calc(1em + 2px);
  margin-bottom: calc(1em - 2px);
  box-shadow: -1px 1px 3px darkgray;
}


input[type="submit"]:enabled,
a.button.primary {
  background-color: #33AA44;
  background-image: linear-gradient(182deg, #44BB55 0%, #33AA44 20%, #228833 100%);
  border-color: #5fc1ff;
  display: block;
  width: fit-content;
  margin-right: 0;
  margin-left: auto;
}

input[type="submit"]:enabled:active, input[type="submit"]:enabled:focus, input[type="submit"]:enabled:hover,
a.button.primary:active, a.button.primary:focus, a.button.primary:hover {
  background-image: linear-gradient(182deg, #55CC66 0%, #44BB55 20%, #339944 100%);
}

a.button.secondary {
  background-image: linear-gradient(182deg,#777,#727272 20%,#555);
  border-color: #888;
}

a.button.secondary:active, a.button.secondary:focus, a.button.secondary:hover {
  background-image: linear-gradient(182deg,grey,#777 20%,#666);
}

#root > footer .some img {
  height: 1.2em;
  margin-left: 1px;
  vertical-align: bottom;
  filter: invert(1) contrast(200%) brightness(150%);
}

figure {
  margin: 4em 0;
}

figure img {
  width: 100%;
  margin-bottom: 1em;
}

figcaption {
  font-style: italic;
}

img.shadow {
  box-shadow: -2px 5px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 5px;
}

h4 + ul {
  margin-top: 5px;
}

aside {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

aside p {
  margin-top: 3em;
}

@media screen and (max-width: 299px) {
  #root {
    padding: 1em;
  }
  blockquote::before {
    width: 2rem;
  }
  
  blockquote p {
    margin-left: 2rem;
  }
  
  blockquote footer {
    margin-left: 2rem;
  }
  

}

@media screen and (max-width: 999px) {
  body {
    padding-bottom: 9em;
  }
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 530px) {
  body {
    padding-bottom: 11em;
  }
  h1 {
    font-size: 2rem;
  }
  a.button {
    display: block;
    min-width: auto;
    box-sizing: border-box;
    max-width: 75vw;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
  }
  a.button + a.button {
    margin-left: 0;
  }
  #root {
    border-radius: 20px;
    margin: 4em 4vw;
  }
  #root > footer > div {
    display: block;
  }
  #root > footer span {
    display: block;
  }
  #root > footer span.separator {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  h1 {
    hyphens: none;
  }
  #laitteet {
    margin-left: -3em;
    margin-right: -3em;
    width: calc(100% + 6em);
  }
  #laitteet section {
    display: inline-block;
    box-sizing: border-box;
    width: 33%;
    vertical-align: top;
  }
  #laitteet section ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
  #laitteet section li {
    font-size: 0.8rem;
    margin-left: 0;
  }
  #sensorit, #pumput {
    padding-left: 2.5em;
  }
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 75%;
	}
	50% {
		background-position: 75% 50%;
	}
	100% {
		background-position: 0% 75%;
	}
}
